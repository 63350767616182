<template>
  <div>
    <div class="flix-form-group">
      <saveBtn
        :onClick="
          () => {
            open = !open
          }
        "
      >
        <template v-slot:icon>
          <flixIcon id="plus"></flixIcon>
        </template>
        <template v-slot:text>
          {{ $t('message.add', { name: $tc('message.appointments', 1) }) }}
        </template>
      </saveBtn>
    </div>
    <transition name="flixFadeIn">
      <div class="flix-form-group" v-if="open" :key="open">
        <div class="flix-form-group">
          <div>{{ $t('message.appointmentChangeUserMessage') }}</div>
        </div>
        <div class="flix-form-group">
          <flix-calendar-bold :user="user" :calendar="data.form" />
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  components: {
  },
  props: {
    data: Object,
    saveCalendar: Function
  },
  data() {
    return {
      open: false,
      user: this.$route.params.user
    }
  },
  methods: {},
  mounted() {},
  computed: {}
}
</script>
<style lang="sass" scoped></style>
